<template>
    <div class="mobile-page-layout">

        <div class="mobile-page-header">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">

                    <router-link v-if="listShow" :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>

                    <div v-if="!listShow" class="mobile-page-header-icon pointer back-icon" @click.prevent="listShow = !listShow">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </div>

                    <div class="mobile-page-header-icon">
                        <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path class="fa-primary" d="M96 32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V192H0V112C0 85.49 21.49 64 48 64H96V32zM96 296C96 282.7 106.7 272 120 272H328C341.3 272 352 282.7 352 296C352 309.3 341.3 320 328 320H120C106.7 320 96 309.3 96 296zM232 368C245.3 368 256 378.7 256 392C256 405.3 245.3 416 232 416H120C106.7 416 96 405.3 96 392C96 378.7 106.7 368 120 368H232z" />
                            <path class="fa-secondary" d="M0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM120 320H328C341.3 320 352 309.3 352 296C352 282.7 341.3 272 328 272H120C106.7 272 96 282.7 96 296C96 309.3 106.7 320 120 320zM120 368C106.7 368 96 378.7 96 392C96 405.3 106.7 416 120 416H232C245.3 416 256 405.3 256 392C256 378.7 245.3 368 232 368H120z" />
                        </svg>
                    </div>
                    <div class="mobile-page-header-text">
                        <slot v-if="listShow">یادداشت‌ها</slot>
                        <slot v-if="!listShow">{{ activeNote.title }}</slot>
                    </div>  
                </div>
                <div class="mobile-page-header-actions">

                    <div v-if="listShow" class="mobile-page-header-icon pointer" @click="modal = !modal">
                        <i class="header-uil-icon uil uil-plus-circle"></i>
                    </div>

                    <div v-if="listShow" class="mobile-page-header-icon pointer" @click.prevent="open_new_categories_modal()">
                        <i class="header-uil-icon uil uil-folder-plus" style="font-size: 22px;padding: 5px;"></i>
                    </div>

                    <div v-if="listShow" class="mobile-page-header-icon pointer" @click.prevent="open_categories_modal()">
                        <i class="header-uil-icon uil uil-folder-open" style="font-size: 22px;padding: 4px;"></i>
                    </div>
                
                    
                    <div class="mobile-page-header-menu">
                        <template>
                            <div class="text-center">
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                        <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                    </div>
                                </template>
                                <v-list class="header-help-list">
                                    <v-list-item @click.prevent="get_modal('search')" v-if="listShow">
                                        <v-list-item-title>جستجو</v-list-item-title>
                                    </v-list-item>
                                    
                                    <v-list-item @click.prevent="get_modal('help')">
                                        <v-list-item-title>راهنما</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                </v-menu>
                            </div>
                        </template>                 
                    </div>
                </div>
            </div>
        </div>

        <v-select v-if="listShow" v-model="selectedCategory" @input="setActiveCategory()" class="profile-mobile-view-select mobile-view-select-full" :items="categories" item-text="title"  item-value="id" label="دسته بندی" outlined></v-select>

        <!-- <v-select v-model="selectedCategory" :clearable="false" label="title" :reduce="(option) => option.id" :options="categories" @input="setActiveCategory()"> </v-select> -->

        <div v-if="search && listShow && notes[selectedCategory].length" class="mobile-search-box">
            <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در فهرست یادداشت‌ها" v-model="noteFilterInput" @keyup="filterNotes()"></v-text-field>
        </div>

        <div v-if="listShow && activeList.length == 0" class="noWidget noContent">
            <div>
                <i class="uil uil-notes noContent-icon" aria-hidden="true"></i>
                <p class="noContent-title">فهرست یادداشتی برای نمایش وجود ندارد</p>
                <div class="tgju-btn  m-top tgju-btn-primary" @click="modal = !modal"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد فهرست</div>
            </div>
        </div>

        <table class="widget-table widget-table-lg" v-if="listShow && activeList.length">
            <thead>
                <tr>
                    <th>عنوان</th>
                    <th class="w60 text-center">ویرایش</th>
                    <th class="w60 text-center">حذف</th>
                </tr>
            </thead>
            <tbody>
                <tr class="pointer" v-for="(note, index) in activeList" :key="index" >
                    <td class="border-l bg-gray" @click="openNote(note.id);"> {{ note.title }}</td>
                    <td class="w60 text-center border-l"><i class="uil uil-edit-alt table-icons pointer" @click="open_note_edit_modal(note.id)"></i></td>
                    <td class="w60 text-center"><i class="uil uil-trash table-icons red-color pointer" @click="removeNote(note.id)"></i></td>
                </tr>
            </tbody>
        </table>

        <!-- <div class="add-new-list-mobile" v-if="listShow">
            <div class="mobile-app-input-add btn-w100" @click="modal = !modal">افزودن یادداشت جدید</div>
        </div> -->

        <v-overlay class="modal-overlay" :value="modal" @click="modal = false"></v-overlay>
        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="modal">
                    <v-sheet>
                        <div v-if="listShow" :class="'add-new-list-mobile in-modal ' + (addEditLoading ? 'loading-active' : '') ">
                            <v-text-field class="mobile-app-input" label="عنوان یادداشت" id="newNote" v-model="newNote" @keyup.enter="addNote()"></v-text-field>
                            <div class="mobile-app-input-add" @click="addNote()">افزودن یادداشت</div>
                        </div>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>
        <!-- <div :class="'list-widget-add-container border-top-1 border-0 ' + (addEditLoading ? 'loading-active' : '') ">
            <i class="fa fa-plus list-widget-add-icon" v-tooltip="'افزودن یادداشت جدید'" aria-hidden="true" @click="addNote()"></i>
            <input class="list-widget-input-add" type="text" placeholder="افزودن یادداشت جدید" v-model="newNote" @keyup.enter="addNote()">
        </div> -->

        <!-- <div class="dashboard-widget-tabs-content mb-0 p-15">
            <h2>
                <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path class="fa-primary" d="M96 32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V192H0V112C0 85.49 21.49 64 48 64H96V32zM96 296C96 282.7 106.7 272 120 272H328C341.3 272 352 282.7 352 296C352 309.3 341.3 320 328 320H120C106.7 320 96 309.3 96 296zM232 368C245.3 368 256 378.7 256 392C256 405.3 245.3 416 232 416H120C106.7 416 96 405.3 96 392C96 378.7 106.7 368 120 368H232z" />
                    <path class="fa-secondary" d="M0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM120 320H328C341.3 320 352 309.3 352 296C352 282.7 341.3 272 328 272H120C106.7 272 96 282.7 96 296C96 309.3 106.7 320 120 320zM120 368C106.7 368 96 378.7 96 392C96 405.3 106.7 416 120 416H232C245.3 416 256 405.3 256 392C256 378.7 245.3 368 232 368H120z" />
                </svg>
                <span class="page-title-svg">یادداشت‌ها و اسناد</span>
            </h2>
            <div class="dashboard-widget-h-btns">
                <a href="" @click.prevent="showSidebar()" class="m-left m-top tgju-btn tgju-btn-primary hide-min-1200 d-w-min-hide hide-pages-mobile-btn" v-tooltip="'یادداشت‌ها'"><i class="uil uil-notes"></i> <span class="hide-769"> یادداشت‌ها</span></a>
                <a href="" @click.prevent="open_new_categories_modal()" class="m-left m-top tgju-btn tgju-btn-primary hide-pages-mobile-btn" v-tooltip="'افزودن‌پوشه'"><i class="uil uil-folder-plus"></i> <span class="hide-769"> افزودن‌پوشه</span></a>
                <a href="" @click.prevent="open_categories_modal()" class="m-left m-top tgju-btn tgju-btn-light hide-pages-mobile-btn" v-tooltip="'مدیریت پوشه‌ها'"><i class="uil uil-folder-open"></i> <span class="hide-769"> مدیریت پوشه‌ها</span></a>
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
        
        <div class="mobile-btns-box show-pages-mobile-btn" style="padding: 0;margin-bottom: 10px;">
            <a href="" @click.prevent="showSidebar()" class="tgju-btn tgju-btn-primary" v-tooltip="'یادداشت‌ها'"><i class="uil uil-notes"></i> <span> یادداشت‌ها</span></a>
            <a href="" @click.prevent="open_new_categories_modal()" class="tgju-btn tgju-btn-primary" v-tooltip="'افزودن‌پوشه'"><i class="uil uil-folder-plus"></i> <span class="hide-769"> افزودن‌پوشه</span></a>
            <a href="" @click.prevent="open_categories_modal()" class="tgju-btn tgju-btn-light" v-tooltip="'مدیریت پوشه‌ها'"><i class="uil uil-folder-open"></i> <span class="hide-769"> مدیریت پوشه‌ها</span></a>
        </div> -->

            <slot v-if="widgetLoad">
                <div class="tgju-widget-content full-padding">
                    <div class="text-center p-2">
                        <div class="widget-loading"></div>
                    </div>
                </div>
            </slot>
            <slot v-else>
                <div v-if="listShow == false" class="froala-textarea">
                    <froala :tag="'textarea'" :config="editorConfig" class="editor" ref="editor"></froala>
                </div>
                <!-- <div class="content-box fix-content"  :class="{'t-display-none': listShow == true}">
                
                    <div v-if="!activeList.length">
                        <div class="popup-dash-empty">
                            <i aria-hidden="true" class="uil uil-exclamation"></i>
                            <span>یادداشتی برای نمایش وجود ندارد</span>
                            <span>لطفا ابتدا یک یادداشت ایجاد نمایید</span>
                        </div>
                    </div>
                    <div v-else style="border-top: 1px solid #dedfe1;border-radius: 0;">
                    </div>
                </div>
                <div class="c-sidebar fix-sidebar" :class="{'t-display-none': listShow == false}">
                    <div class="sidebar-title-row show-t-flex">
                        <h3></h3>
                        <i class="uil uil-times pointer" @click.prevent="hideSidebar()"></i>
                    </div>
                    <div class="d-flex mt-2">
                        <div class="widget-select folder-tags db flex-grow-1">
                            <v-select v-model="selectedCategory" :clearable="false" label="title" :reduce="(option) => option.id" :options="categories" @input="setActiveCategory()">
                            </v-select>
                        </div>s
                    </div>
                    <input class="widget-input m-top w-100" type="text" placeholder="جستجو در فهرست یادداشت ها" @keyup="filterNotes()" v-model="noteFilterInput">
                    <div class="list-widget list-dash-items list-widget-structure">
                        <draggable v-model="activeList" group="notes" @start="drag=true" @end="saveNotesSort()" ghost-class="drag-ghost" handle=".list-drag">
                            <template v-for="note in activeList">
                                <div :key="note.id" v-if="!note.hide" :class="'list-row item-draggable hover bold ' + (activeNote.id  == note.id? 'active-item' : '')">
                                    <div class="list-col l-item pointer" v-tooltip="'جابجایی'">
                                        <i class="uil uil-arrows-v list-drag"></i>
                                    </div>
                                    <div class="list-col l-title l-title-widget-structure" @click="openNote(note.id); makeResponsive(2);">
                                        <span>{{ note.title }}</span>
                                    </div>
                                    <div class="list-col l-item blue-color pointer" @click="open_note_edit_modal(note.id)">
                                        <i class="uil uil-edit-alt" v-tooltip="'ویرایش'"></i>
                                    </div>
                                    <div class="list-col l-item red-color pointer" @click="removeNote(note.id)">
                                        <i class="uil uil-trash remove-row" v-tooltip="'حذف'"></i>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                        <div :class="'list-widget-add-container border-top-1 border-0 ' + (addEditLoading ? 'loading-active' : '') ">
                            <i class="fa fa-plus list-widget-add-icon" v-tooltip="'افزودن یادداشت جدید'" aria-hidden="true" @click="addNote()"></i>
                            <input class="list-widget-input-add" type="text" placeholder="افزودن یادداشت جدید" v-model="newNote" @keyup.enter="addNote()">
                        </div>
                    </div>
                </div> -->
            </slot>

    </div>
</template>

<style lang="scss" scoped>
    .widget-min-box-content {
        .l-title {
            display: flex;
        }

        .edit-note {
            font-size: 14px;
            cursor: pointer;
            margin-right: -3px;
        }
    }
</style>

<script>
// این کامپوننت وظیفه اجرای اجزاری یاداشت و اسناد را در خود دارا می باشد 
// import draggable from 'vuedraggable'
// import vSelect2 from 'vue-select'
import $ from 'jquery'
// Vue.component('v-select2', vSelect2);

export default {
    name: 'NoteMobile',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        // draggable
    },
    data() {
        return {
            modal: false,
            currentNoteId: 0,
            listShow: true,
            newNote: '',
            notes: [],
            activeList: [],
            noteFilterInput: '',
            activeNote: {
                id: null,
                user_id: null,
                category_id: null,
                title: '',
                content: '',
                created_at: null,
            },
            categories: [],
            activeCategory: {
                id: null,
                title: '',
            },
            selectedCategory: null,
            allowSave: false,
            editor: null,
            editorConfig: {
                direction: 'ltr',
                language: 'fa',
                charCounterCount: true,
                placeholderText: 'متن خود را تایپ نمایید',
                typingTimer: 3000,
                requestHeaders: {
                    authorization: `Basic ${this.$store.state.accessToken}`
                },
                emoticonsUseImage: false,
                toolbarButtons: {
                    moreText: {
                        buttons: ['bold', 'fontSize', 'textColor', 'fontFamily', 'backgroundColor', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'inlineClass'],
                        align: 'left',
                        buttonsVisible: 1
                    },
                    moreParagraph: {
                        buttons: ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                        align: 'left',
                        buttonsVisible: 0
                    },
                    moreRich: {
                        buttons: [ /*'insertImage',*/ 'emoticons', 'insertLink', 'insertTable', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR', 'spellChecker'],
                        align: 'left',
                        buttonsVisible: 2
                    },
                    moreMisc: {
                        buttons: ['redo', 'undo', /*'print', */ /*'getPDF',*/ ],
                        align: 'right',
                        buttonsVisible: 2
                    }
                },
                toolbarButtonsXS: [
                    ['undo', 'redo'],
                    ['bold', 'italic', 'underline']
                ],
                imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
                imageUploadURL: this.$helpers.getApiUrl('/note/upload'),
                imageUploadParams: {},
                fileUploadURL: this.$helpers.getApiUrl('/note/upload'),
                fileUploadParams: {},
                events: {
                    initialized: () => {
                        this.editor = this.$refs.editor.getEditor();
                    },
                    contentChanged: () => {
                        if (this.allowSave) {
                            this.saveNote();
                        }
                    },
                    focus: () => {
                        this.allowSave = true;
                    },
                    blur: () => {
                        this.allowSave = false;
                    }
                }
            },
            responsive: {
                rightSection: true,
                leftSection: false,
            },
            widgetLoad: true,
            addEditLoading: false,
            search:false
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                // تزریق داده های اصلی به کامپوننت
                this.loadData();
            }
        }, { immediate: true });
        
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    watch: {
        // بر طرف کردن لود اولیه یادداشت
        editor: {
            handler: function (newValue, oldValue) {
                this.openNote(this.activeList[this.currentNoteId].id);
            }
        }
    },
    methods: {
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }else if(name == 'search'){
                this.search = !this.search;
            }
        },
        // این متد وظیفه‌ی تزریق داده های اصلی به کامپوننت
        loadData() {
            this.categories = this.data.categories;

            if (this.categories.length) {
                this.activeCategory = this.categories[0];
            }

            this.selectedCategory = this.activeCategory.id;

            this.notes = this.data.notes;
            if (typeof this.notes[this.activeCategory.id] != 'undefined') {
                this.setActiveList(this.notes[this.activeCategory.id]);
                // this.openNote(this.activeList[0].id);
            }
            this.widgetLoad = false;
        },
        setActiveList(list) {
            list = this.$helpers.unbindObject(list);

            this.activeList = [];
            list.forEach(note => {
                note.hide = false;
                this.activeList.push(note);
            });
        },
        // این متد وظیفه باز کردن سند مورد نظر کاربر را دارد
        openNote(id) {
            let index = this.$helpers.getIndex(this.activeList, id) ?? 0;
            this.activeNote = this.$helpers.unbindObject(this.activeList[index]);
            if (this.editor) {
                this.editor.html.set(this.activeNote.content);
                this.editor.undo.reset();
            }
            this.currentNoteId = index;
            this.listShow = false;
        },
        // این متد وظیفه ی افزودن سند جدید می باشد
        addNote() {
            if (!this.newNote) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان یادداشت را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }
            if (!this.newNote) return;
            this.addEditLoading = true;
            if (this.noteFilterInput) {
                this.noteFilterInput = '';
                this.filterNotes();
            }

            let id = 'id-' + new Date().getTime();

            let index = this.activeList.push({
                id,
                user_id: null,
                category_id: this.activeCategory.id,
                title: this.newNote,
                content: '',
                created_at: null,
                hide: false
            });

            let api_data = {
                title: this.newNote,
                category_id: this.activeCategory.id,
            };

            this.$helpers.makeRequest('POST', '/note/save', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let inserted_id = api_response.data.response.id;

                    this.activeList.forEach((note, index) => {
                        if (note.id == id) {
                            note.id = inserted_id;

                            this.updateSource();
                        }
                    });
                    this.newNote = '';
                    this.openNote(inserted_id);
                    this.$swal.fire({
                        icon: "success",
                        title: "یادداشت با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد یادداشت رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                }
            });
            this.modal = false;
        },
        // این متد زمانی که در حال نوشتن محتوای سند یا یاداشت هستید کال میشود
        saveNote() {
            if (!this.activeNote.id) {
                return;
            }

            // TODO: برای پاک کردن المنت ایموجی مجبور شدم موقت این حرکت رو بزنم تا یونیکد ایموجی رو بکشم بیرون
            $("p").find(".fr-emoticon").contents().unwrap();

            this.activeNote.content = this.editor.html.get(true);

            let index = this.$helpers.getIndex(this.activeList, this.activeNote.id);
            this.activeList[index].content = this.activeNote.content;

            let api_data = {
                id: this.activeNote.id,
                title: this.activeNote.title,
                content: this.activeNote.content
            };

            this.$helpers.makeRequest('POST', '/note/save', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    //
                    Vue.prototype.$toast.success({
                        title: 'ذخیره',
                        message: 'یادداشت با موفقیت ذخیره شد',
                    });
                } else {
                    Vue.prototype.$toast.warn({
                        title: 'ذخیره',
                        message: 'مشکلی در ذخیره یادداشت رخ داده است',
                    });
                }
            });
        },
        // این متد برای حذف کردن سند یا یاداشت استفاده میشود
        removeNote(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.$helpers.getIndex(this.activeList, id);

                    this.activeList.splice(index, 1);

                    let next_index = index - 1;
                    if (next_index < 0) next_index = 0;

                    if (typeof this.activeList[next_index] != 'undefined') {
                        // this.openNote(this.activeList[next_index].id);
                    } else {
                        this.resetActiveNote();
                    }

                    this.$helpers.makeRequest('DELETE', '/note/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.updateSource();
                            this.$swal.fire({
                                icon: "success",
                                title: "یادداشت با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف یادداشت رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد برای ریست کردن سند یا یاداشت استفاده میشود
        resetActiveNote() {
            this.activeNote.id = null;
            this.activeNote.user_id = null;
            this.activeNote.category_id = null;
            this.activeNote.title = '';
            this.activeNote.content = '';
            this.activeNote.created_at = null;

            if (this.editor) {
            this.editor.html.set(this.activeNote.content);
            this.editor.undo.reset();
            }
        },
        // این متد برای هندل کردن ترتیب قرارگیری و سورت کردن سند یا یاداشت استفاده میشود
        saveNotesSort() {
            let sort = [];
            this.activeList.forEach(note => {
                sort.push(note.id);
            });

            let api_data = {
                sort: JSON.stringify(sort),
            };

            this.$helpers.makeRequest('POST', '/note/sort', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'ذخیره',
                        message: 'مرتب سازی با موفقیت ذخیره شد',
                    });
                } else {
                    Vue.prototype.$toast.warn({
                        title: 'ذخیره',
                        message: 'مشکلی در مرتب سازی رخ داده است',
                    });
                }
            });
        },
        // این متد برای هندل کردن مودال تنظیمات در ویجت استفاده میشود
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/Note.vue',
                config: {
                    data: {
                        title: this.widget.title,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (settings) => {
                this.widget.title = settings.title;
                let widget = this.$helpers.unbindObject(this.widget);
                this.$helpers.saveWidget(widget);
            });
        },
        // این متد مودال و کال بک مدیریت دسته بدنی ها و یا پوشه های یاداشت را کنترل میکند
        open_categories_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_manageNoteCategoriesList_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'NoteCategoriesList.vue',
                config: {
                    title: 'مدیریت پوشه ها',
                    smallModal: true,
                    data: {
                        widget: this.widget,
                        categories: this.categories
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'addCategory') {
                    this.categories.push(response.data.category);
                } else if (response.action == 'editCategory') {
                    let index = this.$helpers.getIndex(this.categories, response.data.id);

                    this.categories.filter((category, index) => {
                        if (category.id == response.data.category.id) {
                            category.title = response.data.category.title;
                        }
                    });
                } else if (response.action == 'removeCategory') {
                    let index = this.$helpers.getIndex(this.categories, response.data.id);
                    this.categories.splice(index, 1);
                    this.$emit('reloadNotes');
                }
            });
        },
        // این متد مودال و کال بک ایجاد یک دسته بدنی  و یا پوشه‌ی یادداشت را کنترل میکند
        open_new_categories_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_manageNoteCategoriesNew_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'NoteCategoriesNew.vue',
                config: {
                    title: 'ایجاد پوشه جدید',
                    smallModal: true,
                    data: {
                        widget: this.widget,
                        categories: this.categories
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                this.categories.push(response.data.category);
            });
        },
        // این متد مودال و کال بک ویرایش عنوان یاداشت و یا سند را کنترل میکند
        open_note_edit_modal(id = null) {
            let callbackEvent = this.$router.currentRoute.name + '_EditNotes_' + new Date().getTime();

            if (id > 0) {
                let index = this.$helpers.getIndex(this.activeList, id);
                this.activeNote = this.activeList[index];
            }

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'NoteEdit.vue',
                config: {
                    title: 'ویرایش یادداشت',
                    smallModal: true,
                    data: {
                        note: this.$helpers.unbindObject(this.activeNote),
                        categories: this.$helpers.unbindObject(this.categories)
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            // کال بک فانکشن
            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'editNote') {
                    if (this.noteFilterInput) {
                        this.noteFilterInput = '';
                        this.filterNotes();
                    }

                    this.activeNote.title = response.data.note.title;
                    this.activeNote.category_id = response.data.note.category_id;

                    let categoryChanged = (this.activeNote.category_id != this.activeCategory.id) ? true : false;

                    this.activeList.forEach((note, index) => {
                        if (note.id == this.activeNote.id) {
                            note.title = this.activeNote.title;
                            note.category_id = this.activeNote.category_id;

                            if (categoryChanged) {
                                this.activeList.splice(index, 1);

                                if (typeof this.notes[this.activeNote.category_id] == 'undefined') {
                                    this.notes[this.activeNote.category_id] = [];
                                }

                                this.notes[this.activeNote.category_id].push(note);
                            }
                        }
                    });

                    let api_data = {
                        title: this.activeNote.title,
                        category_id: this.activeNote.category_id,
                    };

                    // ارسال درخواست به وب سرویس جهت ذخیره سازی
                    this.$helpers.makeRequest('POST', '/note/edit/' + this.activeNote.id, api_data).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.updateSource();
                            this.$swal.fire({
                                icon: "success",
                                title: "یادداشت با موفقیت بروزرسانی شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در بروزرسانی یادداشت رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد جهت تعویض دسته بندی و یا پوشه فعال در یاداشت و سند استفاده میشود
        setActiveCategory() {
            this.categories.forEach(category => {
                if (category.id == this.selectedCategory) {
                    this.activeCategory = category;
                }
            });

            if (this.noteFilterInput) {
                this.noteFilterInput = '';
                this.filterNotes();
            }

            if (typeof this.notes[this.activeCategory.id] != 'undefined') {
                this.setActiveList(this.notes[this.activeCategory.id]);
                // this.openNote(this.activeList[0].id);
            } else {
                this.activeList = [];
                this.resetActiveNote();
            }
        },
        // این متد جهت فیلتر کردن یادداشت ها و سند ها استفاده میشود
        filterNotes() {
            let activeList = [];
            Object.values(this.notes).forEach((list, listIndex) => {
                list.forEach((note, index) => {
                    if (note.title.indexOf(this.noteFilterInput.trim()) > -1) {
                        activeList.push(note);
                    }
                });
            });

            if (!this.noteFilterInput.trim()) {
                if (typeof this.notes[this.activeCategory.id] != 'undefined') {
                    activeList = this.notes[this.activeCategory.id];
                } else {
                    activeList = [];
                }
            }

            this.setActiveList(activeList);

            // if ( this.activeList.length ) {
            // 	this.openNote(this.activeList[0].id);
            // } else {
            // 	this.resetActiveNote();
            // }
        },
        updateSource() {
            this.notes[this.activeCategory.id] = this.activeList;
        },
        makeResponsive(mode) {
            if (mode == 1) {
                this.responsive.rightSection = true;
                this.responsive.leftSection = false;
            } else if (mode == 2) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = true;
            } else if (mode == 3) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = false;
            }
        },
        showSidebar() {
            this.listShow = true;
        },
        hideSidebar() {
            this.listShow = false;
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Notes.vue',
                title: this.widget.title,
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
